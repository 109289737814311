import { msg, str } from '@lit/localize'
import { TrackingMixin } from '#js/components/TrackingMixin'
import { getAlgoliaAnalyticsData } from '#js/integrations/algoliaSearch'
import { html } from 'lit'
import { localize } from '#js/components/utils'

export class EventList extends TrackingMixin {
  static properties = {
    events: { type: Array },
    error: { type: Boolean, attribute: false },
    phone: { type: String },
    formattedPhone: { type: String }
  }

  applySettings (settings) {
    this.eventTransformer = new AlgoliaEventTransformer(
      settings.watched_offers,
      settings.previously_booked_offers
    )
  }

  render () {
    if (this.error) {
      return html`
        <link rel="stylesheet" href="${window.styleFilePath}">
        <div class="discovery-list">
          <div class="discovery-list__error">
            <h2>
              ${msg(str`Sorry something went wrong.`)}
            </h2>
            <p>${msg(str`Please try again later.`)}</p>
            <svg viewBox="0 0 300 300" class="discovery-list__error__image"
                 style="height: 8em">
              <use href="${window.svgSprite}#Schubkarre">
              </use>
            </svg>
          </div>
        </div>
      `
    }
    // `this.events` is undefined until the first search result is received
    if (this.events === undefined) {
      return html`
        <link rel="stylesheet" href="${window.styleFilePath}">
        <div class="discovery-list">
          <div class="discovery-list__loading">
            <svg viewBox="0 0 32 32" class="discovery-list__loading__svg">
              <g>
                <use href="${window.svgSprite}#voFerienaktivitaet">
                </use>
                <animateTransform attributeType="xml" attributeName="transform"
                                  type="rotate" from="360 16 16" to="0 16 16" dur="3s"
                                  additive="sum" repeatCount="indefinite"/>
              </g>
            </svg>
          </div>
        </div>
      `
    }
    if (this.events.length === 0) {
      return html`
        <link rel="stylesheet" href="${window.styleFilePath}">
        <div class="discovery-list">
          <div class="discovery-list__error layout">
            <h2>
              ${msg(str`We could not find any offers with the selected filters.`)}
            </h2>
            <div style="padding: var(--space--half) 0">
              <svg viewBox="0 0 483 398" height="250" style="color: var(--brand-color)">
                <use href="${window.svgSprite}#womanHeadset">
                </use>
              </svg>
            </div>
            <div>
              <p>
                ${msg(str`Ask our customer service team for help!`)}
              </p>
              <div class="row row--centered">
                <strong>
                  <a
                    href="tel:${this.phone}"
                    class="service-team-links">
                    <svg class="icon icon--large">
                      <use href="${window.svgSprite}#voPhone">
                      </use>
                    </svg>
                    <span>${this.formattedPhone}</span>
                  </a>
                  <a href="mailto:beratung@voiio.app" class="service-team-links">
                    <svg class="icon icon--large">
                      <use href="${window.svgSprite}#voMail">
                      </use>
                    </svg>
                    <span>beratung@voiio.app</span>
                  </a>
                </strong>
              </div>
            </div>
          </div>
        </div>
      `
    }
    return html`
      <link rel="stylesheet" href="${window.styleFilePath}">
      <div class="discovery-list">
        ${this.events.map(event => html`
          <offer-card .offer=${event}
                      promotionId=${this.trackingInformation.promotion_id}
                      promotionName=${this.trackingInformation.promotion_name}
                      itemListId=${this.trackingInformation.item_list_id}
                      itemListName=${this.trackingInformation.item_list_name}
          ></offer-card>
        `)}
      </div>
    `
  }

  eventsChanged (events, hitsPerPage, currentPage, algoliaQueryId) {
    this.events = events.map(
      (hit, index) => this.eventTransformer.transform(
        hit, index, hitsPerPage, currentPage, algoliaQueryId
      )
    )
  }
}

customElements.define('event-list', EventList)

export class AlgoliaEventTransformer {
  constructor (watchedOffers, previouslyBookedOffers, eapCostCoverageText) {
    this.watchedOffers = watchedOffers
    this.previouslyBookedOffers = previouslyBookedOffers
    this.eapCostCoverageText = eapCostCoverageText
  }

  /**
   * Transform an Algolia search result item to an event for the offer card.
   * @param {object} hit - Algolia search result item
   * @param {number} index - index of the item in the search result
   * @param {number} hitsPerPage - number of hits per page
   * @param {number} currentPage - current page number
   * @param {number} algoliaQueryId - ID to identify the initial search request
   * @returns {object} event - transformed event
   */
  transform (hit, index, hitsPerPage, currentPage, algoliaQueryId) {
    const type = hit.objectID.split('-')[0]
    let title = hit.title
    let subtitle = hit.parent_object_title
    let watched = false
    let previouslyBooked = false
    let bookOptionTimer = null

    let picture = null
    if (hit.picture) {
      picture = {
        ratios: {
          '3/2': {
            sources: hit.picture.sources,
            media: hit.picture.media
          },
          '1/1': {
            sources: hit.picture.sources,
            media: hit.picture.media
          }
        }
      }
    } else {
      console.error('No picture found for event ', hit.objectID)
    }

    let hostPicture
    if (hit.host_picture) {
      hostPicture = {
        ratios: {
          '1/1': {
            sources: hit.host_picture.sources,
            media: hit.host_picture.media
          }
        }
      }
    }

    if (['bookingoption', 'offerlist', 'targetgroup', 'topic'].includes(type)) {
      subtitle = hit.parent_object_subtitle
    }

    // different talks share the same subtitle from the same series,
    // so we display description (aka abstract) instead
    if (type === 'talk') {
      subtitle = hit.description
    }

    if (type === 'bookingoption') {
      const parentObjectId = parseInt(hit.parent_object_id.split('-')[1])
      watched = this.watchedOffers.includes(parentObjectId)
      previouslyBooked = this.previouslyBookedOffers.includes(parentObjectId)
      title = hit.parent_object_title
    }

    if (hit.start_date) {
      bookOptionTimer = localize(new Date(hit.start_date * 1000))
    }

    const languages = hit.languages?.reduce((accumulator, code) => {
      const key = code.toLowerCase()
      accumulator[key] = code
      return accumulator
    }, {})

    return {
      type: type === 'expert' ? 'expert' : 'offer',
      sort_order: 0,
      id: hit.id,
      url: hit.public_url,
      public_url: hit.public_url,
      activity_type: hit.activity_type,
      activity_type_display: hit.activity_type_display,
      min_retail_price: hit.retail_price,
      booking_option_timer: bookOptionTimer,
      location: hit.location,
      age_range: hit.age_range_display,
      previously_booked: previouslyBooked,
      provider_title: hit.provider_title,
      job_title: hit.job_title,
      areas_of_expertise: hit.areas_of_expertise,
      brand: hit.brand_name,
      watch_url: hit.watch_url,
      unwatch_url: hit.unwatch_url,
      languages: languages || {},
      eap_support: hit.eap_support,
      title,
      subtitle,
      watched,
      picture,
      host_picture: hostPicture,
      algoliaAnalytics: getAlgoliaAnalyticsData(hit, index, hitsPerPage, currentPage, algoliaQueryId)
    }
  }
}
